/* eslint-disable @next/next/no-img-element */
import Link from "next/link";
import { SOURCES } from "../../constants";
import Likes from "../likes";

const PromptItem = ({
  _id,
  thumbnail,
  promptName,
  promptPrice,
  source,
  likes,
  isPaid,
}) => {
  return (
    <article>
      <div className="dark:bg-jacarta-700 dark:border-jacarta-700 border-jacarta-100 rounded-2xl block border bg-white p-[1.1875rem] transition-shadow hover:shadow-lg text-jacarta-500">
        <figure>
          <Link href={`/item/${_id}`}>
            <a>
              <div className="w-full">
                <img
                  src={thumbnail}
                  alt={promptName}
                  height={230}
                  width={230}
                  className="rounded-[0.625rem] w-full"
                  loading="lazy"
                />
              </div>
            </a>
          </Link>
        </figure>
        <div className="mt-4 flex items-center justify-between">
          <Link href={`/item/${_id}`}>
            <a>
              <span className="font-display text-jacarta-700 hover:text-accent text-base dark:text-white">
                {promptName}
              </span>
            </a>
          </Link>
          <span className="dark:border-jacarta-600 border-jacarta-100 flex items-center whitespace-nowrap rounded-md border py-1 px-2">
            <span className="text-green text-sm font-medium tracking-tight">
              {SOURCES[source]}
            </span>
          </span>
        </div>
        <div className="mt-2 text-sm">
          {/* <span className="dark:text-jacarta-300 text-jacarta-500">
                      Price
                    </span> */}
          <span className="dark:text-jacarta-100 text-jacarta-700 font-semibold">
            {isPaid ? `₹ ${promptPrice}` : "Free to use"}
          </span>
        </div>

        <div className="mt-8 flex items-center justify-between">
          {isPaid && (
            <Link
              href={"/item/" + _id}
              className="text-accent font-display text-sm font-semibold"
            >
              Buy
            </Link>
          )}
          <Likes
            like={likes?.length || 0}
            classes="flex items-center space-x-1"
          />
        </div>
      </div>
    </article>
  );
};
export default PromptItem;
