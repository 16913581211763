/* eslint-disable @next/next/no-img-element */
import React from "react";
import "tippy.js/dist/tippy.css";
import PromptItem from "../carousel/PromptItem";

const CategoryItem = ({ prompts = [] }) => {
  return (
    <div className="grid grid-cols-1 gap-[1.875rem] md:grid-cols-2 lg:grid-cols-4">
      {prompts.map((item) => {
        return <PromptItem key={item._id} {...item} />;
      })}
    </div>
  );
};

export default CategoryItem;
